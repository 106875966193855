<template lang="pug">
  div
    TheHeader
    .wrapper.policy
      .policy_wrap
        h2 {{ $t('legalDocs.lawEnforcementTitle') }}
        .policy_updated {{ $t('legalDocs.lastUpdated') }}: {{ updatedLegal(updatedAt, this.$i18n.locale) }}
        div(v-html="$t('legalDocs.lawEnforcementFull')")
</template>

<script>
import TheHeader from '@/components/TheHeader';
import { updatedLegal } from '@/dates';

export default {
  components: { TheHeader },
  data() {
    return { updatedAt: '2020-04-30' };
  },
  methods: {
    updatedLegal(date, locale) {
      return updatedLegal(date, locale);
    }
  }
};
</script>

<style lang="scss" scoped>
  ul { padding-left: 40px !important; }
</style>
